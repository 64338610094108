// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amazon-marketplace-js": () => import("../src/pages/amazon-marketplace.js" /* webpackChunkName: "component---src-pages-amazon-marketplace-js" */),
  "component---src-pages-atg-brand-js": () => import("../src/pages/atg_brand.js" /* webpackChunkName: "component---src-pages-atg-brand-js" */),
  "component---src-pages-career-js": () => import("../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-conversion-js": () => import("../src/pages/conversion.js" /* webpackChunkName: "component---src-pages-conversion-js" */),
  "component---src-pages-creative-design-js": () => import("../src/pages/creative_design.js" /* webpackChunkName: "component---src-pages-creative-design-js" */),
  "component---src-pages-cueblocks-for-good-js": () => import("../src/pages/cueblocks-for-good.js" /* webpackChunkName: "component---src-pages-cueblocks-for-good-js" */),
  "component---src-pages-email-marketing-js": () => import("../src/pages/email-marketing.js" /* webpackChunkName: "component---src-pages-email-marketing-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magento-1-to-magento-2-migration-service-js": () => import("../src/pages/magento-1-to-magento-2-migration-service.js" /* webpackChunkName: "component---src-pages-magento-1-to-magento-2-migration-service-js" */),
  "component---src-pages-magento-1-to-shopify-migration-service-js": () => import("../src/pages/magento-1-to-shopify-migration-service.js" /* webpackChunkName: "component---src-pages-magento-1-to-shopify-migration-service-js" */),
  "component---src-pages-magento-js": () => import("../src/pages/magento.js" /* webpackChunkName: "component---src-pages-magento-js" */),
  "component---src-pages-magento-2-js": () => import("../src/pages/magento2.js" /* webpackChunkName: "component---src-pages-magento-2-js" */),
  "component---src-pages-magento-2-casestudy-search-engine-js": () => import("../src/pages/magento2/casestudy/search-engine.js" /* webpackChunkName: "component---src-pages-magento-2-casestudy-search-engine-js" */),
  "component---src-pages-marketplaces-js": () => import("../src/pages/marketplaces.js" /* webpackChunkName: "component---src-pages-marketplaces-js" */),
  "component---src-pages-merging-blog-pages-seo-js": () => import("../src/pages/merging-blog-pages-seo.js" /* webpackChunkName: "component---src-pages-merging-blog-pages-seo-js" */),
  "component---src-pages-organic-search-js": () => import("../src/pages/organic_search.js" /* webpackChunkName: "component---src-pages-organic-search-js" */),
  "component---src-pages-paid-search-js": () => import("../src/pages/paid_search.js" /* webpackChunkName: "component---src-pages-paid-search-js" */),
  "component---src-pages-php-js": () => import("../src/pages/php.js" /* webpackChunkName: "component---src-pages-php-js" */),
  "component---src-pages-resources-js": () => import("../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-search-engine-optimization-js": () => import("../src/pages/search-engine-optimization.js" /* webpackChunkName: "component---src-pages-search-engine-optimization-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shopify-js": () => import("../src/pages/shopify.js" /* webpackChunkName: "component---src-pages-shopify-js" */),
  "component---src-pages-site-speed-ww-js": () => import("../src/pages/site-speed-ww.js" /* webpackChunkName: "component---src-pages-site-speed-ww-js" */),
  "component---src-pages-slick-slider-demo-js": () => import("../src/pages/slick-slider-demo.js" /* webpackChunkName: "component---src-pages-slick-slider-demo-js" */),
  "component---src-pages-team-js": () => import("../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-wordpress-js": () => import("../src/pages/wordpress.js" /* webpackChunkName: "component---src-pages-wordpress-js" */),
  "component---src-pages-work-js": () => import("../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

